
import Vue from 'vue';

export default Vue.extend({
  name: 'Admin',

  computed: {
    currentAward () {
      return this.$store.state.currentAward
    },
  },

  created () {
    this.$store.commit('setDarkMode', true)
    this.$store.dispatch('getUsers', '')
    this.$store.dispatch('getAllAwards')
    console.log('# Admin created darkMode: ', this.$store.state.darkMode);
  },

  mounted () {
    this.$store.commit('setDarkMode', true)
    console.log('# Admin mounted darkMode: ', this.$store.state.darkMode);
  },

  destroyed () {
    this.$store.commit('setDarkMode', false)
    console.log('# Admin destroyed darkMode: ', this.$store.state.darkMode);
  }
});
